import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { graphql } from '@/gql';
import { Talent } from '@/gql/graphql';
import { ScoutDetail } from './ScoutDetail';
import { TalentProfile } from './TalentProfile';

export const TalentDetail_Query = graphql(/* GraphQL */ `
  query TalentDetail_Query($id: String!) {
    talent(id: $id) {
      id
      createdAt
      updatedAt

      name
      email
      nameKana

      scoutAgents {
        id
        createdAt
        updatedAt

        project {
          id
          createdAt
          updatedAt

          name
        }
      }

      scouts {
        id
        createdAt
        updatedAt

        project {
          id
          createdAt
          updatedAt

          name
        }
      }

      agreements {
        id
        createdAt
        updatedAt
      }

      talentProfile {
        id
        age
        github
        twitter
        qiita
        zenn
        weeklyWorkingDayMin
        weeklyWorkingDayMax
        priceHourlyMax
        priceHourlyMin
        startDate

        wantToDo
        workstyle
        availabilityStatus

        talentProfileMasterEngineerPositions {
          id
          name
        }

        talentProfileMasterEngineerSkills {
          id
          name
          category {
            id
            name
          }
        }

        talentProfileWorkHistories {
          companyName
          projectName
          jobDescription
          periodFrom
          periodTo

          talentProfileWorkHistoryMasterEngineerPositions {
            id
            name
          }
        }
      }
    }
  }
`);

export const TalentDetail = () => {
  const { userId } = useParams() as { userId: string };
  const [result] = useQuery({
    query: TalentDetail_Query,
    variables: { id: userId },
  });

  const { data } = result;

  if (!data?.talent) {
    throw new Promise((resolve) => resolve(null));
  }

  const talent = data.talent;

  return (
    <PageLayout title={talent?.name || ''}>
      <div className="flex w-full flex-row gap-4">
        <div className="w-2/3">{talent && <TalentProfile talent={talent as Talent} />}</div>
        <div className="w-1/3">
          <React.Suspense fallback="...">{talent && <ScoutDetail talent={talent as Talent} />}</React.Suspense>
        </div>
      </div>
    </PageLayout>
  );
};
