import { Text } from '@4design/for-ui';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { HyperLink } from '@/components/ui-parts/Link';
import { MessageTypeEnum, Scout, ScoutMessage } from '@/gql/graphql';
import { formatDate, formatDateTime } from '@/lib/dateformat';

type Props = {
  scout: Scout;
};

export const MessageList = (props: Props) => {
  return (
    <div className="bg-shade-light-default flex w-full flex-1 flex-col items-start gap-4 overflow-y-scroll p-6">
      {props.scout.messages.map((item) => {
        const isOwnMessage = item.organizationUser;

        if (item.messageType === MessageTypeEnum.SystemScout) {
          return <ScoutMessageItem key={item.id} message={item} />;
        }

        if (item.messageType === MessageTypeEnum.SystemAgreement) {
          return <AgreemntMessageItem key={item.id} message={item} />;
        }
        return isOwnMessage ? <MyMessageItem message={item} /> : <AnotherMessageItem message={item} />;
      })}
    </div>
  );
};

type MessageItemProps = {
  message: ScoutMessage;
};

const ScoutMessageItem = (props: MessageItemProps) => {
  const createdAt = formatDateTime(props.message.createdAt);
  return (
    <div className="flex w-full flex-row justify-start">
      <div className="flex w-full flex-col items-start gap-1">
        <div className="bg-shade-white-default border-shade-light-default rounded-t-2 rounded-br-2 flex w-full flex-col items-start gap-1 border p-4">
          <div className="bg-informative-light-default py1- w-full rounded px-2">
            <Text size="r" className="text-informative-dark-default whitespace-pre-wrap">
              {props.message.message}
            </Text>
          </div>

          <Text weight="bold" size="r" className="text-shade-dark-default whitespace-pre-wrap">
            案件
          </Text>

          <HyperLink
            underline
            label={props.message.scout.project?.name}
            size="r"
            icon
            to={`/project/${props.message.scout.project?.id}`}
          />

          <Text weight="bold" size="r" className="text-shade-dark-default whitespace-pre-wrap">
            条件
          </Text>

          <LabeledDList
            // label="希望条件"
            details={[
              {
                label: '希望時給',
                content: (
                  <Text size="r">
                    {props.message.scout.talent.talentProfile.priceHourlyMin
                      ? `${props.message.scout.talent.talentProfile.priceHourlyMin?.toLocaleString()}円`
                      : ''}
                    ~
                    {props.message.scout.talent.talentProfile.priceHourlyMax
                      ? `${props.message.scout.talent.talentProfile.priceHourlyMax?.toLocaleString()}円`
                      : ''}
                  </Text>
                ),
              },
              {
                label: '希望稼働日数 (週)',
                content: (
                  <Text size="r">
                    {props.message.scout.talent.talentProfile.weeklyWorkingDayMin
                      ? `${props.message.scout.talent.talentProfile.weeklyWorkingDayMin?.toLocaleString()}日`
                      : ''}
                    ~
                    {props.message.scout.talent.talentProfile.weeklyWorkingDayMax
                      ? `${props.message.scout.talent.talentProfile.weeklyWorkingDayMax?.toLocaleString()}日`
                      : ''}
                  </Text>
                ),
              },
              {
                label: '希望稼働開始時期',
                content: <Text size="r">{formatDate(props.message.scout.talent.talentProfile.startDate)}</Text>,
              },
            ]}
          />
        </div>

        <div className="flex items-start justify-end gap-4">
          <Text size="s" className="text-shade-dark-default">
            {createdAt}
          </Text>
        </div>
      </div>
    </div>
  );
};

const AgreemntMessageItem = (props: MessageItemProps) => {
  console.info(props.message.agreement);

  const createdAt = formatDateTime(props.message.createdAt);
  return (
    <div className="flex w-full flex-row justify-start">
      <div className="flex w-full flex-col items-start gap-1">
        <div className="bg-shade-white-default border-shade-light-default rounded-t-2 rounded-br-2 flex w-full flex-col items-start gap-1 border p-4">
          <div className="bg-informative-light-default py1- w-full rounded px-2">
            <Text size="r" className="text-informative-dark-default whitespace-pre-wrap">
              {props.message.message}
            </Text>
          </div>

          <Text weight="bold" size="r" className="text-shade-dark-default whitespace-pre-wrap">
            条件
          </Text>

          <LabeledDList
            // label="希望条件"
            details={[
              {
                label: '時給',
                content: <Text size="r">{props.message.agreement?.priceHourly?.toLocaleString()}円</Text>,
              },
              {
                label: '稼働予定時間',
                content: <Text size="r">{props.message.agreement?.workingHourPerMonth?.toLocaleString()}時間</Text>,
              },
              {
                label: '希望稼働開始時期',
                content: <Text size="r">{formatDate(props.message.agreement?.startDate)}</Text>,
              },
            ]}
          />
        </div>

        <div className="flex items-start justify-end gap-4">
          <Text size="s" className="text-shade-dark-default">
            {createdAt}
          </Text>
        </div>
      </div>
    </div>
  );
};

const AnotherMessageItem = (props: MessageItemProps) => {
  const createdAt = formatDateTime(props.message.createdAt);
  return (
    <div className="flex w-full flex-row justify-start">
      <div className="flex flex-col items-start gap-1">
        <div className="flex flex-row items-end gap-2">
          <div className="bg-shade-white-default border-shade-light-default rounded-t-2 rounded-br-2 flex flex-row items-start border p-4">
            <Text size="r" className="text-shade-dark-default whitespace-pre-wrap">
              {props.message.message}
            </Text>
          </div>
        </div>
        <div className="flex items-start justify-end gap-4">
          <Text size="s" className="text-shade-dark-default">
            {props.message.talent?.name}
          </Text>
          <Text size="s" className="text-shade-dark-default">
            {createdAt}
          </Text>
        </div>
      </div>
    </div>
  );
};

const MyMessageItem = (props: MessageItemProps) => {
  const createdAt = formatDateTime(props.message.createdAt);

  return (
    <div className="flex w-full flex-row justify-end">
      <div className="flex flex-col items-end gap-1">
        <div className="flex flex-row items-end gap-2">
          <div className="bg-primary-light-default border-shade-light-default rounded-t-2 rounded-bl-2 flex flex-row items-start border p-4">
            <Text size="r" className="text-shade-dark-default whitespace-pre-wrap">
              {props.message.message}
            </Text>
          </div>
        </div>

        <div className="flex items-start justify-end gap-4">
          <Text size="s" className="text-shade-dark-default">
            {props.message.organizationUser?.name}
          </Text>
          <Text size="s" className="text-shade-dark-default">
            {createdAt}
          </Text>
        </div>
      </div>
    </div>
  );
};
