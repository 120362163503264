import React from 'react';
import { useQuery } from 'urql';
import { ColumnDef, Table } from '@4design/for-ui';
import { graphql } from '@/gql';
import { InputMaybe, Talent, TalentFilter } from '@/gql/graphql';
import { usePagination } from '@/hooks/usePagination';
import { useSearchParamsObject } from '@/hooks/useSearchParamsObject';
import { TalentTableRow } from './TalentTableRow';

type TalentTableQueryResult = {
  experienceJobType: string;
  languageFramework: string;
  middleware: string;
  infra: string;
  monitoringTool: string;
  configurationManagementTool: string;
  hourlyWageLowerLimit: string;
  hourlyWageUpperLimit: string;
  isConsidering: string;
};

export const TalentTable_Query = graphql(/* GraphQL */ `
  query TalentTable_Query($filter: TalentFilter, $offset: Int!, $limit: Int!) {
    talents(filter: $filter, offset: $offset, limit: $limit) {
      nodes {
        id
        createdAt
        updatedAt

        name
        email
        nameKana
        hasPool

        scouts {
          id

          project {
            id
          }
        }

        scoutAgents {
          id

          project {
            id
          }
        }

        talentProfile {
          id
          age
          github
          twitter
          qiita
          zenn
          weeklyWorkingDayMin
          weeklyWorkingDayMax
          priceHourlyMax
          priceHourlyMin
          startDate

          wantToDo
          workstyle
          availabilityStatus

          talentProfileMasterEngineerPositions {
            id
            name
          }

          talentProfileMasterEngineerSkills {
            id
            name
            category {
              id
              name
            }
          }

          talentProfileWorkHistories {
            id
            companyName
            projectName
            jobDescription
            periodFrom
            periodTo
          }
        }
      }

      pageInfo {
        offset
        total
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const columns: ColumnDef<Talent, any>[] = [
  {
    header: '候補者',
    accessorKey: 'person',
  },
  {
    header: '技術スタック',
    accessorKey: 'technicalStack',
  },
  {
    header: '職歴',
    accessorKey: 'drafts',
  },
  {
    header: '希望条件',
    accessorKey: 'wish',
  },
  {
    header: 'アクション',
    accessorKey: 'action',
  },
];

export const TalentTable = () => {
  const { page, limit, offset, totalPageCount, actions: paginationActions } = usePagination();
  const { createSearchParamsObject } = useSearchParamsObject();

  const queryParams = createSearchParamsObject<Partial<TalentTableQueryResult>>();

  const buildTalentFilter = (): InputMaybe<TalentFilter> | undefined => {
    const filter: InputMaybe<TalentFilter> = {};
    if (queryParams?.configurationManagementTool) {
      filter.configurationManagementTools = queryParams.configurationManagementTool?.split(',') ?? undefined;
    }
    if (queryParams?.experienceJobType) {
      filter.engineerPositions = queryParams.experienceJobType?.split(',') ?? undefined;
    }
    if (queryParams?.infra) {
      filter.infrastructures = queryParams.infra?.split(',') ?? undefined;
    }
    if (queryParams?.languageFramework) {
      filter.engineerSkills = queryParams.languageFramework?.split(',') ?? undefined;
    }
    if (queryParams?.middleware) {
      filter.middlewares = queryParams.middleware?.split(',') ?? undefined;
    }
    if (queryParams?.monitoringTool) {
      filter.monitoringTools = queryParams.monitoringTool?.split(',') ?? undefined;
    }
    if (queryParams?.hourlyWageLowerLimit) {
      filter.priceHourlyMin = Number(queryParams.hourlyWageLowerLimit);
    }
    if (queryParams?.hourlyWageUpperLimit) {
      filter.priceHourlyMax = Number(queryParams.hourlyWageUpperLimit);
    }
    if (queryParams?.isConsidering) {
      filter.consideringOnly = queryParams.isConsidering === 'true' ? true : false;
    }

    if (Object.keys(filter).length > 0) {
      return filter;
    }

    return undefined;
  };

  const talentFilter = buildTalentFilter();

  const [result] = useQuery({
    query: TalentTable_Query,
    variables: {
      filter: talentFilter,
      limit,
      offset,
    },
  });

  const data = (result.data?.talents.nodes as Talent[]) ?? [];
  const totalTalentCount = result.data?.talents.pageInfo.total;

  return (
    <React.Suspense>
      <Table<Talent>
        rowRenderer={(row) => {
          const talent = row.row.original;
          return <TalentTableRow row={talent} />;
        }}
        columns={columns}
        getRowId={(row) => row.id}
        data={data}
        page={page}
        pageCount={totalPageCount(totalTalentCount)}
        pageSize={limit}
        defaultPage={1}
        onChangePage={paginationActions.onChange}
      />
    </React.Suspense>
  );
};
