import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { createClient, mapExchange } from 'urql';
import { config } from '@/config';
import { auth } from '@/lib/firebase';
import { yogaExchange } from '@graphql-yoga/urql-exchange';
import { cacheExchange } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';

export async function getToken(): Promise<any> {
  return await new Promise((resolve: any, reject: any) =>
    onAuthStateChanged(
      auth,
      async (user: User | null) => {
        if (user) {
          resolve(await user.getIdToken());
        }
      },
      (e: any) => {
        console.error(e);
        reject(e);
      },
    ),
  );
}

export const urqlClient = createClient({
  url: config.endpoint,
  requestPolicy: 'cache-and-network',
  suspense: true,
  exchanges: [
    mapExchange({
      onError(error, _operation) {
        console.error(error);
      },
    }),

    authExchange(async (utils) => {
      const _auth = getAuth();
      const token = await getToken();
      return {
        addAuthToOperation(operation) {
          if (!token) return operation;
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
        willAuthError: () => {
          return false;
        },
        didAuthError: (error) => {
          return error.graphQLErrors.some(({ message }) => /requires authentication/i.test(message));
        },
        refreshAuth: async () => {
          await signOut(_auth);
          return;
        },
      };
    }),
    cacheExchange,
    yogaExchange(),
  ],
});
