import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Badge, Text } from '@4design/for-ui';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { HyperLink } from '@/components/ui-parts/Link/HyperLink';
import { PROJECT_DETAIL_PATH, TALENT_DETAIL_PATH } from '@/constants/routes';
import { graphql } from '@/gql';
import { formatDate } from '@/lib/dateformat';

export const ProjectDetailPanel_Query = graphql(/* GraphQL */ `
  query ProjectDetailPanel_Query($id: String!) {
    scout(id: $id) {
      id
      createdAt
      updatedAt

      organization {
        id
        name
      }

      project {
        id
        createdAt
        updatedAt

        name
        status

        details
        requiredSkill
        welcomeSkill
        teamComposition
        targetAgeMax
        targetAgeMin
        startDate
        expectedDuration
        weeklyWorkingDayMin
        weeklyWorkingDayMax
        interviewCount
        priceTaxType
        priceHourlyMax
        priceHourlyMin
        priceMonthlyMax
        priceMonthlyMin
        priceSettlementHourFrom
        priceSettlementHourTo
        paymentDate
      }

      talent {
        id
        name
        talentProfile {
          id
          startDate
          weeklyWorkingDayMin
          weeklyWorkingDayMax
          priceHourlyMax
          priceHourlyMin

          talentProfileMasterEngineerPositions {
            id
            name
          }

          talentProfileMasterEngineerSkills {
            id
            name
          }
        }
      }
    }
  }
`);

export const ProjectDetailPanel = () => {
  const { messageId } = useParams() as {
    messageId: string;
  };

  const [result] = useQuery({
    query: ProjectDetailPanel_Query,
    variables: {
      id: messageId,
    },
  });

  if (!result.data) {
    throw new Error('data is not found', result.error);
  }

  const talent = result.data.scout.talent;
  const project = result.data.scout.project;

  return (
    <div className="border-shade-light-default rounded-r-2 bg-shade-white-default flex h-[calc(100vh-56px-32px)] flex-col overflow-y-scroll border-y border-r p-4">
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Text className="text-shade-dark-default" size="s" weight="bold">
            案件
          </Text>
          <HyperLink
            underline
            label={project.name}
            size="r"
            icon
            to={PROJECT_DETAIL_PATH.replace(':projectId', project.id)}
          />
        </div>
        <LabeledDList
          label="基本情報"
          hyperLink={{
            label: '詳細なプロフィールを見る',
            to: TALENT_DETAIL_PATH.replace(':userId', talent.id),
          }}
          details={[
            {
              label: '名前',
              content: <Text size="r">{talent.name}</Text>,
            },
            // {
            //   label: '年齢',
            //   content: <Text size="r">25歳</Text>,
            // },
            {
              label: '経験職種',
              content: (
                <div className="flex flex-row flex-wrap gap-1">
                  {talent.talentProfile.talentProfileMasterEngineerPositions.map((v) => (
                    <Badge key={v.id} variant="outlined" intention="shade" label={v.name} />
                  ))}
                </div>
              ),
            },
            {
              label: '技術スタック',
              content: (
                <div className="flex flex-row flex-wrap gap-1">
                  {talent.talentProfile.talentProfileMasterEngineerSkills.map((v) => (
                    <Badge key={v.id} variant="outlined" intention="shade" label={v.name} />
                  ))}
                </div>
              ),
            },
          ]}
        />

        <LabeledDList
          label="希望条件"
          details={[
            {
              label: '希望時給',
              content: (
                <Text size="r">
                  {talent.talentProfile.priceHourlyMin
                    ? `${talent.talentProfile.priceHourlyMin?.toLocaleString()}円`
                    : ''}
                  ~
                  {talent.talentProfile.priceHourlyMax
                    ? `${talent.talentProfile.priceHourlyMax?.toLocaleString()}円`
                    : ''}
                </Text>
              ),
            },
            {
              label: '希望稼働日数 (週)',
              content: (
                <Text size="r">
                  {talent.talentProfile.weeklyWorkingDayMin
                    ? `${talent.talentProfile.weeklyWorkingDayMin?.toLocaleString()}日`
                    : ''}
                  ~
                  {talent.talentProfile.weeklyWorkingDayMax
                    ? `${talent.talentProfile.weeklyWorkingDayMax?.toLocaleString()}日`
                    : ''}
                </Text>
              ),
            },
            {
              label: '希望稼働開始時期',
              content: <Text size="r">{formatDate(talent.talentProfile.startDate)}</Text>,
            },
          ]}
        />
      </div>
    </div>
  );
};
