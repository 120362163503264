import { useMutation } from 'urql';
import { FormCreateProjectInput } from '@/features/project/repository/project.model';
import { graphql } from '@/gql';
import { CreateProjectInput, UpdateProjectInput } from '@/gql/graphql';

const OnCreateProject_Mutation = graphql(/* GraphQL */ `
  mutation OnCreateProject_Mutation($input: CreateProjectInput!) {
    createProject(input: $input) {
      __typename
      data {
        __typename
        id
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const OnCloseProject_Mutation = graphql(/* GraphQL */ `
  mutation OnCloseProject_Mutation($input: CloseProjectInput!) {
    closeProject(input: $input) {
      __typename
      data {
        __typename
        id
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const OnPublishProject_Mutation = graphql(/* GraphQL */ `
  mutation OnPublishProject_Mutation($input: PublishProjectInput!) {
    publishProject(input: $input) {
      __typename
      data {
        __typename
        id
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const OnDuplicateProject_Mutation = graphql(/* GraphQL */ `
  mutation OnDuplicateProject_Mutation($input: DuplicateProjectInput!) {
    duplicateProject(input: $input) {
      __typename
      data {
        __typename
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const OnUpdateProject_Mutation = graphql(/* GraphQL */ `
  mutation OnUpdateProject_Mutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      __typename
      data {
        __typename
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const parseStringToNumber = (value: string | null | undefined) => {
  if (!value) {
    return null;
  }
  const num = Number(value);
  if (isNaN(num)) {
    return null;
  }
  return num;
};

const parseStringToDate = (value: string | null | undefined) => {
  if (!value) {
    return null;
  }
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
};

const buildCreateRequest = (input: FormCreateProjectInput) => {
  const {
    engineerPositionIds,
    engineerSkillLanguageIds,
    engineerSkillFrameworkIds,
    engineerSkillMiddlewareIds,
    engineerSkillInfraIds,
    ...rest
  } = input;
  const engineerSKillIds = [
    ...(engineerSkillLanguageIds ?? []),
    ...(engineerSkillFrameworkIds ?? []),
    ...(engineerSkillMiddlewareIds ?? []),
    ...(engineerSkillInfraIds ?? []),
  ];

  const request: CreateProjectInput = {
    name: rest.name,
    details: rest.details,
    paymentDate: parseStringToDate(rest.paymentDate),
    expectedDuration: parseStringToNumber(rest.expectedDuration),
    priceHourlyMax: parseStringToNumber(rest.priceHourly.max),
    priceHourlyMin: parseStringToNumber(rest.priceHourly.min),
    priceMonthlyMax: parseStringToNumber(rest.priceMonthly.max),
    priceMonthlyMin: parseStringToNumber(rest.priceMonthly.min),
    priceSettlementHourFrom: parseStringToNumber(rest.priceSettlementHour.from),
    priceSettlementHourTo: parseStringToNumber(rest.priceSettlementHour.to),
    priceTaxType: rest.priceTaxType,
    privateName: rest.privateName,
    requiredSkill: rest.requiredSkill,
    startDate: rest.startDate,
    targetAgeMax: parseStringToNumber(rest.targetAge.max),
    targetAgeMin: parseStringToNumber(rest.targetAge.min),
    teamComposition: rest.teamComposition,
    weeklyWorkingDayMax: parseStringToNumber(rest.weeklyWorkingDay.max),
    weeklyWorkingDayMin: parseStringToNumber(rest.weeklyWorkingDay.min),
    welcomeSkill: rest.welcomeSkill,
    engineerPositionIds: engineerPositionIds?.map((item) => item.inputValue) ?? [],
    engineerSkillIds: engineerSKillIds.map((item) => item.inputValue) ?? [],
  };

  return request;
};

const buildUpdateRequest = (input: FormCreateProjectInput, projectId: string) => {
  const {
    engineerPositionIds,
    engineerSkillLanguageIds,
    engineerSkillFrameworkIds,
    engineerSkillMiddlewareIds,
    engineerSkillInfraIds,
    ...rest
  } = input;
  const engineerSKillIds = [
    ...(engineerSkillLanguageIds ?? []),
    ...(engineerSkillFrameworkIds ?? []),
    ...(engineerSkillMiddlewareIds ?? []),
    ...(engineerSkillInfraIds ?? []),
  ];

  const request: UpdateProjectInput = {
    id: projectId,
    name: rest.name,
    details: rest.details,
    paymentDate: parseStringToDate(rest.paymentDate),
    expectedDuration: parseStringToNumber(rest.expectedDuration),
    priceHourlyMax: parseStringToNumber(rest.priceHourly.max),
    priceHourlyMin: parseStringToNumber(rest.priceHourly.min),
    priceMonthlyMax: parseStringToNumber(rest.priceMonthly.max),
    priceMonthlyMin: parseStringToNumber(rest.priceMonthly.min),
    priceSettlementHourFrom: parseStringToNumber(rest.priceSettlementHour.from),
    priceSettlementHourTo: parseStringToNumber(rest.priceSettlementHour.to),
    priceTaxType: rest.priceTaxType,
    privateName: rest.privateName,
    requiredSkill: rest.requiredSkill,
    startDate: rest.startDate,
    targetAgeMax: parseStringToNumber(rest.targetAge.max),
    targetAgeMin: parseStringToNumber(rest.targetAge.min),
    teamComposition: rest.teamComposition,
    weeklyWorkingDayMax: parseStringToNumber(rest.weeklyWorkingDay.max),
    weeklyWorkingDayMin: parseStringToNumber(rest.weeklyWorkingDay.min),
    welcomeSkill: rest.welcomeSkill,
    engineerPositionIds: engineerPositionIds?.map((item) => item.inputValue) ?? [],
    engineerSkillIds: engineerSKillIds.map((item) => item.inputValue) ?? [],
  };

  return request;
};

export const useProject = () => {
  const [, onCreateProject] = useMutation(OnCreateProject_Mutation);
  const onCreate = async (input: FormCreateProjectInput) => {
    const request = buildCreateRequest(input);

    const res = await onCreateProject({
      input: request,
    });

    if (res.error) {
      throw res.error;
    }

    return res.data;
  };

  const [, onCloseProject] = useMutation(OnCloseProject_Mutation);
  const onClose = async (projectIds: string[]) => {
    const res = await onCloseProject({
      input: {
        projectIds,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return res.data;
  };

  const [, onPublishProject] = useMutation(OnPublishProject_Mutation);
  const onPublish = async (projectId: string) => {
    console.info({
      projectId,
    });
    const res = await onPublishProject({
      input: {
        id: projectId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return res.data;
  };

  const [, onDuplicateProject] = useMutation(OnDuplicateProject_Mutation);
  const onDuplicate = async (projectId: string) => {
    const res = await onDuplicateProject({
      input: {
        id: projectId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return res.data;
  };

  const [, onUpdateProject] = useMutation(OnUpdateProject_Mutation);
  const onUpdate = async (input: FormCreateProjectInput, projectId: string) => {
    const request = buildUpdateRequest(input, projectId);
    const res = await onUpdateProject({
      input: request,
    });

    if (res.error) {
      throw res.error;
    }

    return res.data;
  };

  return { onCreate, onClose, onDuplicate, onUpdate, onPublish };
};
